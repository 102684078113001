<template>
    <div class="container">
        <div class="terms mb-5">
            <h3>{{$t("Terms and Conditions")}}
            </h3>
            <div class="term1 mt-5">
                <h6>{{ $t("terms of use") }}
                </h6>
                <p>
               {{$t("This text is an example of text that can be replaced in the same space. This text was generated from the Arabic text generator, where you can generate such text or many other texts in addition to increasing the number of letters that the application generates.")}}
                </p>
                <p>
                  
                 {{$t("If you need a larger number of paragraphs, the Arabic text generator allows you to increase the number of paragraphs as you want. The text will not appear divided and does not contain linguistic errors. The Arabic text generator is useful for website designers in particular, as the client often needs to see a real image. for website design.")}}
                </p>
                <p>
{{ $t("Hence, the designer must place temporary texts on the design to show the client the complete form. The role of the Arabic text generator is to save the designer the trouble of searching for alternative text that has nothing to do with the topic the design is talking about, so it appears in an inappropriate way. This text can be installed on any Design without a problem will not look like copied text, disorganized, unformatted, or even incomprehensible. Because it is still an alternative and temporary text.") }}</p>
            </div>

            <div class="term1 mt-5">
                <h6> {{ $t("terms of use") }}</h6>
                <p>{{$t("This text is an example of text that can be replaced in the same space. This text was generated from the Arabic text generator, where you can generate such text or many other texts in addition to increasing the number of letters that the application generates.")}}
                </p>
                <p>
                  إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.

                </p>
                <p>
                  ومن هنا وجب على المصمم أن يضع نصوصا مؤقتة على التصميم ليظهر للعميل الشكل كاملاً،دور مولد النص العربى أن يوفر على المصمم عناء البحث عن نص بديل لا علاقة له بالموضوع الذى يتحدث عنه التصميم فيظهر بشكل لا يليق.هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير منظم، غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.

                </p>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "termsAndConditions",

  data() {
    return {
      body: {
        userName: null,
        email: null,
        image: null,
      },
      isInvalid: false,
      disabledAdd: false,
      id: null,
    };
  },
}
</script>
<style>

</style>